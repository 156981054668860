import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Paper from '@mui/material/Paper'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DataInput } from './components/DataInput'
import { ProductSelection } from './components/ProductSelection'
import { AdditionalData } from './components/AdditionalData'
import { Summary } from './components/Summary'
import { useRecoilValue } from 'recoil'
import { stepState } from './recoil/atoms/step'
import { plPL } from '@mui/material/locale'

export interface Product {
  id: string
  name: string
  desc: string
  price: number
  discount?: number
  quantity?: number
}

const steps = [
  'Dane wejściowe',
  'Wybór pozycji',
  'Uzupełnianie danych',
  'Podsumowanie',
]

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <DataInput />
    case 1:
      return <ProductSelection />
    case 2:
      return <AdditionalData />
    case 3:
      return <Summary />
    default:
      throw new Error('Unknown step')
  }
}

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  plPL
)

function App() {
  const step = useRecoilValue(stepState)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position='absolute'
        color='default'
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant='h6' color='inherit' noWrap>
            Ofertowanie iLOQ
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component='main' maxWidth='xl' sx={{ mb: 4 }}>
        <Paper
          variant='outlined'
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stepper activeStep={step} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {getStepContent(step)}
        </Paper>
      </Container>
    </ThemeProvider>
  )
}

export default App
