import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { CSSProperties, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSetRecoilState } from 'recoil'
import { read, utils } from 'xlsx'
import { Product } from '../App'
import { productsState } from '../recoil/atoms/products'
import { stepState } from '../recoil/atoms/step'

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export const DataInput = () => {
  const [isFileAccepted, setIsFileAccepted] = useState<boolean>(false)
  const setStep = useSetRecoilState(stepState)
  const setProducts = useSetRecoilState(productsState)

  const onDrop = async ([file]) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const contents = e.target.result
      const wb = read(contents)
      const data: Product[] = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: ['id', 'name', 'desc', 'price'],
        range: 1,
      })

      // add quantity and discount
      const dataWithQuantity: Product[] = data.map((el) => {
        el.quantity = 0
        el.discount = 0

        return el
      })

      setProducts(dataWithQuantity)
      setIsFileAccepted(true)
    }

    reader.readAsArrayBuffer(file)
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {acceptedFiles.length ? (
          <p>{acceptedFiles[0].name}</p>
        ) : (
          <p>Przeciągnij i upuść tutaj plik xlsx albo kliknij aby wybrać.</p>
        )}
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          onClick={() => setStep(1)}
          sx={{ mt: 3, ml: 1 }}
          disabled={!isFileAccepted}
        >
          Dalej
        </Button>
      </Box>
    </>
  )
}
