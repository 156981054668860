import { Product } from '../App'
import { Metadata } from '../recoil/atoms/metadata'

export class DocDefinitionGenerator {
  products: Product[]
  metadata: Metadata
  content: any[]
  styles: any
  showDiscountColumn: boolean

  constructor(products: Product[], metadata: Metadata) {
    this.products = products
    this.metadata = metadata
    this.content = []
    this.showDiscountColumn = products.some((p) => p.discount > 0)
  }

  generate() {
    this.addHeader()
    this.addTable()
    this.addTotals()
    this.addFooter()
    this.addStyles()

    return {
      content: this.content,
      styles: this.styles,
    }
  }

  addHeader() {
    // brand
    this.content.push({
      svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 65.8" xmlns:v="https://vecta.io/nano"><path d="M.8 64.3h12.7V20.9H.8v43.4zM14.4 7.2A7.17 7.17 0 0 0 7.2 0 7.17 7.17 0 0 0 0 7.2a7.17 7.17 0 0 0 7.2 7.2 7.17 7.17 0 0 0 7.2-7.2m108.2 28.6c0-16.6-13.5-30-30.2-30s-30.2 13.4-30.2 30c0 16.5 13.5 30 30.2 30s30.2-13.4 30.2-30m-12.2 0c0 9.9-8 17.8-18 17.8-9.9 0-18-8-18-17.8 0-9.9 8-17.8 18-17.8s18 8 18 17.8M60.9 52.4H39.5V7.2h-13v57.1H54l6.9-11.9zm121.7 1.5c3.8-5 6.1-11.3 6.1-18.1 0-9.5-4.4-17.9-11.3-23.4l-6.5 10.4c3.5 3.3 5.6 7.8 5.6 13 0 9.9-8 17.8-18 17.8-2.2 0-4.4-.4-6.3-1.2l-6.5 10.4c3.9 1.8 8.3 2.9 12.9 2.9h0 34.1l6.4-10.2 1-1.7-17.5.1h0zM171.7 8.8c-4-1.9-8.4-3-13.2-3-16.7 0-30.2 13.4-30.2 30 0 9.6 4.5 18.1 11.6 23.6l6.5-10.4c-3.6-3.3-5.9-8-5.9-13.2 0-9.9 8-17.8 18-17.8 2.3 0 4.6.5 6.6 1.3l6.6-10.5h0z" fill-rule="evenodd" fill="#0a485f"/></svg>',
      width: 150,
      margin: [0, 0, 0, 50],
    })

    // meta

    // client meta
    const clientStack: any[] = ['Klient:']

    if (this.metadata.clientName) {
      clientStack.push({ text: this.metadata.clientName, bold: true })
    }

    if (this.metadata.clientAddress) {
      clientStack.push(this.metadata.clientAddress)
    }

    if (this.metadata.clientNip) {
      clientStack.push(`NIP: ${this.metadata.clientAddress}`)
    }

    this.content.push({
      columns: [
        clientStack,
        [
          {
            text: `Data wystawienia: ${this.metadata.offerDate.format(
              'DD.MM.YYYY'
            )}`,
            alignment: 'right',
          },
          {
            text: `Ważny do: ${this.metadata.offerValidity.format(
              'DD.MM.YYYY'
            )}`,
            alignment: 'right',
          },
        ],
      ],
      margin: [0, 0, 0, 30],
    })

    // title
    this.content.push({ text: this.metadata.offerTitle, style: 'header' })
  }

  addTable() {
    // body
    const tableBody = []

    if (this.showDiscountColumn) {
      tableBody.push([
        'Pozycja',
        'Ilość',
        'Cena netto',
        'Rabat',
        'Wartość netto',
      ])
    } else {
      tableBody.push(['Pozycja', 'Ilość', 'Cena netto', 'Wartość netto'])
    }

    this.products.forEach((product) => {
      if (this.showDiscountColumn) {
        tableBody.push([
          {
            stack: [product.name, product.desc, product.id],
          },
          product.quantity,
          product.price,
          `${product.discount}%`,
          this.round(
            product.quantity * product.price * (1 - product.discount / 100)
          ),
        ])
      } else {
        tableBody.push([
          {
            stack: [product.name, product.desc, product.id],
          },
          product.quantity,
          product.price,
          this.round(product.quantity * product.price),
        ])
      }
    })

    // widths
    const widths = this.showDiscountColumn
      ? ['*', 'auto', 'auto', 'auto', 'auto']
      : ['*', 'auto', 'auto', 'auto']

    this.content.push({
      table: {
        body: tableBody,
        widths,
      },
      margin: [0, 0, 0, 30],
    })
  }

  addTotals() {
    const subtotal = this.products.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        currentValue.price *
          currentValue.quantity *
          (1 - currentValue.discount / 100)
      )
    }, 0)
    const subtotalRounded = this.round(subtotal)
    const taxes = this.round(subtotal * 0.23)
    const total = this.round(subtotalRounded + taxes)

    this.content.push({
      stack: [
        { text: `Wartość netto: ${subtotalRounded} zł`, alignment: 'right' },
        { text: `VAT(23%): ${taxes} zł`, alignment: 'right' },
        { text: `Razem: ${total} zł`, bold: true, alignment: 'right' },
      ],
    })
  }

  addFooter() {}

  addStyles() {
    this.styles = {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 30],
      },
    }
  }

  round(amount: number): number {
    return Math.round((amount + Number.EPSILON) * 100) / 100
  }
}
