import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { stepState } from '../recoil/atoms/step'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import Table from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import { productsState } from '../recoil/atoms/products'
import { DocDefinitionGenerator } from '../utils/DocDefinitionGenerator'
import Stack from '@mui/material/Stack'
import { metadataState } from '../recoil/atoms/metadata'
import Typography from '@mui/material/Typography'
pdfMake.addVirtualFileSystem(pdfFonts)

export const Summary = () => {
  const metadata = useRecoilValue(metadataState)
  const products = useRecoilValue(productsState)
  const setStep = useSetRecoilState(stepState)
  const showDiscountColumn = products.some((p) => p.discount > 0)

  const selectedProducts = products.filter((p) => {
    return p.quantity > 0
  })

  const generatePdf = () => {
    const docDefinitionGenerator = new DocDefinitionGenerator(
      selectedProducts,
      metadata
    )
    const docDefinition = docDefinitionGenerator.generate()

    pdfMake.createPdf(docDefinition).download()
  }

  return (
    <>
      <Box>
        <Stack spacing={1}>
          <Typography fontWeight={'bold'}>Klient:</Typography>
          {metadata.clientName && (
            <Typography>{metadata.clientName}</Typography>
          )}
          {metadata.clientAddress && (
            <Typography whiteSpace={'pre-wrap'}>
              {metadata.clientAddress}
            </Typography>
          )}
          {metadata.clientNip && (
            <Typography>NIP: {metadata.clientNip}</Typography>
          )}
          <Typography fontWeight={'bold'}>Oferta:</Typography>
          {metadata.offerTitle && (
            <Typography>{metadata.offerTitle}</Typography>
          )}
          <Typography>
            Data wystawienia: {metadata.offerDate.format('DD.MM.YYYY')}
          </Typography>
          <Typography>
            Data ważności: {metadata.offerValidity.format('DD.MM.YYYY')}
          </Typography>
        </Stack>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Pozycja</TableCell>
                <TableCell align='right'>Ilość</TableCell>
                <TableCell align='right'>Cena&nbsp;netto</TableCell>
                {showDiscountColumn && (
                  <TableCell align='right'>Rabat</TableCell>
                )}
                <TableCell align='right'>Wartość&nbsp;netto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((product) => {
                return (
                  <TableRow
                    key={product.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {product.name}
                      <br />
                      {product.desc}
                      <br />
                      {product.id}
                    </TableCell>
                    <TableCell align='right'>{product.quantity}</TableCell>
                    <TableCell align='right'>{product.price}</TableCell>
                    {showDiscountColumn && (
                      <TableCell align='right'>{product.discount}%</TableCell>
                    )}
                    <TableCell align='right'>
                      {Math.round(
                        (product.quantity *
                          product.price *
                          (1 - product.discount / 100) +
                          Number.EPSILON) *
                          100
                      ) / 100}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setStep(2)} sx={{ mt: 3, ml: 1 }}>
          Powrót
        </Button>

        <Button variant='contained' sx={{ mt: 3, ml: 1 }} onClick={generatePdf}>
          Generuj PDF
        </Button>
      </Box>
    </>
  )
}
