import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { metadataState } from '../recoil/atoms/metadata'
import { stepState } from '../recoil/atoms/step'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/pl'

export const AdditionalData = () => {
  const setStep = useSetRecoilState(stepState)
  const [metadata, setMetadata] = useRecoilState(metadataState)

  const changeMetadata = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMetadata({
      ...metadata,
      [event.target.id]: event.target.value,
    })
  }

  const changeDateMetadata = (field: string, value: Dayjs | null) => {
    setMetadata({
      ...metadata,
      [field]: value,
    })
  }

  return (
    <>
      <Box component='form'>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pl'>
          <Stack spacing={3}>
            <TextField
              id='offerTitle'
              label='Tytuł oferty'
              variant='outlined'
              value={metadata.offerTitle}
              onChange={changeMetadata}
            />
            <TextField
              id='clientName'
              label='Nazwa firmy klienta'
              variant='outlined'
              value={metadata.clientName}
              onChange={changeMetadata}
            />
            <TextField
              id='clientAddress'
              label='Adres firmy klienta'
              value={metadata.clientAddress}
              onChange={changeMetadata}
              multiline
            />
            <TextField
              id='clientNip'
              label='NIP klienta'
              variant='outlined'
              value={metadata.clientNip}
              onChange={changeMetadata}
            />
            <DesktopDatePicker
              label='Data wystawienia'
              inputFormat='DD.MM.YYYY'
              value={metadata.offerDate}
              onChange={(value: Dayjs | null) =>
                changeDateMetadata('offerDate', value)
              }
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label='Ważny do'
              inputFormat='DD.MM.YYYY'
              value={metadata.offerValidity}
              onChange={(value: Dayjs | null) =>
                changeDateMetadata('offerValidity', value)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setStep(1)} sx={{ mt: 3, ml: 1 }}>
          Powrót
        </Button>

        <Button
          variant='contained'
          onClick={() => setStep(3)}
          sx={{ mt: 3, ml: 1 }}
        >
          Dalej
        </Button>
      </Box>
    </>
  )
}
