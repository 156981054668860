import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useSetRecoilState, useRecoilState } from 'recoil'
import { Product } from '../App'
import { productsState } from '../recoil/atoms/products'
import { stepState } from '../recoil/atoms/step'

const replaceItemAtIndex = (
  arr: Product[],
  index: number,
  newValue: Product
) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Kod produktu', flex: 1 },
  { field: 'name', headerName: 'Nazwa produktu', flex: 3 },
  { field: 'desc', headerName: 'Podtytuł', flex: 2 },
  { field: 'price', headerName: 'Cena (PLN)', type: 'number' },
  { field: 'quantity', headerName: 'Ilość', type: 'number', editable: true },
  {
    field: 'discount',
    headerName: 'Rabat (%)',
    type: 'number',
    editable: true,
  },
]

export const ProductSelection = () => {
  const setStep = useSetRecoilState(stepState)
  const [products, setProducts] = useRecoilState(productsState)
  // const isAnyProductSelected = products.some((p) => p.quantity > 0)

  const handleRowUpdate = (newRow: Product) => {
    const index = products.findIndex((el) => el.id === newRow.id)
    const newList = replaceItemAtIndex(products, index, newRow)

    setProducts(newList)

    return newRow
  }

  return (
    <>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={products}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={(error) => console.log(error)}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setStep(0)} sx={{ mt: 3, ml: 1 }}>
          Powrót
        </Button>

        <Button
          variant='contained'
          onClick={() => setStep(2)}
          sx={{ mt: 3, ml: 1 }}
        >
          Dalej
        </Button>
      </Box>
    </>
  )
}
