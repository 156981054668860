import dayjs, { Dayjs } from 'dayjs'
import { atom } from 'recoil'

export interface Metadata {
  clientName: string
  clientAddress: string
  clientNip: string
  offerTitle: string
  offerDate: Dayjs
  offerValidity: Dayjs
}

const today = dayjs()
const todayPlusOneMonth = today.add(1, 'month')

export const metadataState = atom<Metadata>({
  key: 'metadataState',
  default: {
    clientName: '',
    clientAddress: '',
    clientNip: '',
    offerTitle: '',
    offerDate: today,
    offerValidity: todayPlusOneMonth,
  },
})
